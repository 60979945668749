import React from "react";
import { Col } from "react-bootstrap";
import "./IconBox.styles.scss";
const IconBox = ({ iconBox }) => {
  return (
    <Col
      lg={4}
      md={4}
      sm={12}
      className="IconBox d-flex flex-column"
      style={{
        borderRight: iconBox.borderColor
          ? `solid 1px ${iconBox.borderColor}`
          : undefined,
      }}
    >
      {iconBox.icon && (
        <img
          src={iconBox.icon}
          className="img-fluid"
          width="40"
          alt={iconBox.alt}
        />
      )}
      <h4 className="pt-4" style={{ color: iconBox.iconHeadingColor }}>
        {iconBox.heading}
      </h4>
      <p style={iconBox.textColor && { color: iconBox.textColor }}>
        {iconBox.text}
      </p>
      <p style={iconBox.emailColor && { color: iconBox.emailColor }}>
        {iconBox.email}
      </p>
    </Col>
  );
};

export default IconBox;
