import React from "react";
import { Container, Row } from "react-bootstrap";
import GlideSlider from "../GlideSlider/GlideSlider";
import CategoriesData from "../../categoriesData.json";
const Categories = () => {
  return (
    <section>
      <Container>
        <Row>
          <h2 className="h2">
            Perfect Decor for <span>Every Occasion</span>
          </h2>
        </Row>
        <GlideSlider data={CategoriesData} />
      </Container>
    </section>
  );
};

export default Categories;
