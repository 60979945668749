import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Hero.styles.scss";
import { NavLink } from "react-router-dom";
import CustomButton from "../CustomButton/CustomButton";
const Hero = () => {
  return (
    <div className="hero">
      <Container className="d-flex align-items-center justify-content-start">
        <Row>
          <Col lg={6} md={4} sm={12} className="order-md-2">
            <img
              src="/assets/Images/hero-background.png"
              className="img-fluid d-md-none "
              alt=""
            />
          </Col>
          <Col
            lg={6}
            md={8}
            sm={12}
            className="d-flex flex-column gap-2 hero__text order-md-1"
          >
            <h1 className="h1">Decor & Events on Tap</h1>
            <p>
              Decor Tec is a web-based platform transforming the decor and
              events industry with its innovative "Decor & Events on Tap"
              solution.
            </p>
            <div className="buttons d-flex gap-3">
              <NavLink to="/signup">
                <CustomButton buttonType="primary">
                  Signup as a Client
                </CustomButton>
              </NavLink>
              <NavLink to="/signup">
                <CustomButton buttonType="primaryOutline">
                  Signup as a Supplier
                </CustomButton>
              </NavLink>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Hero;
