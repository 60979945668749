import React from "react";
import { Container, Row } from "react-bootstrap";
import IconBox from "../IconBox/IconBox";
import "./IconBoxList.styles.scss";
const IconBoxList = ({ data }) => {
  return (
    <section
      className="IconBoxList"
      style={data.backgroundColor && { backgroundColor: data.backgroundColor }}
    >
      <Container
        style={{
          background: data.sectionBackground,
          minHeight: data.minHeight,
          backgroundSize: "cover",
        }}
        className={`d-flex flex-column  justify-content-between ${
          data.sectionBackground && "IconBoxList__background"
        }`}
      >
        <Row>
          <h2 className="h2 pb-5" style={{ color: data.sectionHeadingColor }}>
            {data.sectionHeading.map((item, index) =>
              index === 1 ? (
                <span className="h2">{item}</span>
              ) : (
                <React.Fragment key={index}>
                  {item} {/* Add a space if needed */}
                </React.Fragment>
              )
            )}
          </h2>
          {data.headingParagraph && <p>{data.headingParagraph}</p>}
        </Row>
        <Row
          className="row-gap-3 "
          style={
            data.rowBackground && {
              backgroundColor: data.rowBackground,
            }
          }
        >
          {data.iconBoxes.map((iconBox) => {
            return <IconBox iconBox={iconBox} />;
          })}
        </Row>
      </Container>
    </section>
  );
};

export default IconBoxList;
