import React from "react";
import DashboardSidebar from "../../components/DashboardSidebar/DashboardSidebar";
import Dashboard from "../../components/Dashboard/Dashboard";
import { Col, Container, Row } from "react-bootstrap";

const DashboardPage = () => {
  return (
    <section>
      <Container>
        <Row>
          <Col md={4}>
            <DashboardSidebar />
          </Col>
          <Col md={8}>
            <Dashboard />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default DashboardPage;
