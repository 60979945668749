import React from "react";
import Card from "react-bootstrap/Card";
import CustomButton from "../CustomButton/CustomButton";
import { Container } from "react-bootstrap";
const Portfolio = () => {
  return (
    <Container className="mt-5">
      <Card>
        <Card.Header>
          <h4>Portfolio</h4>
        </Card.Header>
        <Card.Body>
          <Card.Title>Special title treatment</Card.Title>
          <Card.Text>
            With supporting text below as a natural lead-in to additional
            content.
          </Card.Text>
          <CustomButton buttonType="primary">Go somewhere</CustomButton>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Portfolio;
