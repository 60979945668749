import React from "react";

import ContactDetailsData from "../../ContactDetails.json";
import IconBoxList from "../../components/IconBoxList/IconBoxList";
import ContactForm from "../ContactForm/ContactForm";
import "./ContactPage.styles.scss";
import ContactFormFields from "../../contactFormFields.json";
const ContactPage = () => {
  return (
    <div className="ContactPage">
      <IconBoxList data={ContactDetailsData} />
      <ContactForm fields={ContactFormFields} />
    </div>
  );
};

export default ContactPage;
