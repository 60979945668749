import React from "react";
import { Col, Container, NavLink, Row } from "react-bootstrap";
import "./CallToAction.styles.scss";
import CustomButton from "../CustomButton/CustomButton";
import { Link } from "react-router-dom";
const CallToAction = ({
  heading,
  paragraph,
  buttons,
  buttonsPosition = "start",
}) => {
  return (
    <section className="CallToAction">
      <Container>
        <Row className="d-flex align-items-center">
          <Col lg={6} md={6} sm={12}>
            <h2 className="h2">{heading}</h2>
          </Col>
          <Col lg={6} md={6} sm={12}>
            {paragraph && <p>{paragraph}</p>}
            <div
              className={`buttons d-flex  gap-3 row-gap-3  justify-content-${buttonsPosition}`}
            >
              {buttons.map((button) => {
                return (
                  <Link to={button.buttonLink}>
                    <CustomButton buttonType={button.buttonType}>
                      {button.buttonText}
                    </CustomButton>
                  </Link>
                );
              })}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default CallToAction;
