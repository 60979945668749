import React, { useState } from "react";
import {
  Form,
  Button,
  ProgressBar,
  Row,
  Col,
  Container,
} from "react-bootstrap";
import "./RegisterPage.styles.scss";
import supplierRegistrationFormData from "../../supplierRegistrationFormData.json";
import CustomButton from "../../components/CustomButton/CustomButton";

const RegisterPage = () => {
  const [step, setStep] = useState(1);
  const [selectedOptions, setSelectedOptions] = useState({});

  const totalSteps = supplierRegistrationFormData.steps.length;

  const nextStep = (e) => {
    e.preventDefault();
    setStep((prev) => Math.min(prev + 1, totalSteps));
  };
  const prevStep = (e) => {
    e.preventDefault();
    setStep((prev) => Math.max(prev - 1, 1));
  };

  const handleCheckboxChange = (fieldId, value) => {
    setSelectedOptions((prev) => ({
      ...prev,
      [fieldId]: prev[fieldId]
        ? prev[fieldId].includes(value)
          ? prev[fieldId].filter((item) => item !== value)
          : [...prev[fieldId], value]
        : [value],
    }));
  };

  return (
    <section className="RegisterPage">
      <Container>
        <div className="p-4">
          {/* Step Progress */}
          <div className="d-flex justify-content-center align-items-center mb-3 gap-5">
            {supplierRegistrationFormData.steps.map((label, index) => {
              const progress =
                step > index + 1 ? 100 : step === index + 1 ? 50 : 0;

              return (
                <div
                  key={index}
                  className="d-flex  align-items-center  w-100 justify-content-center text gap-0"
                >
                  <div className="w-100">
                    <div
                      className={`rounded-circle ${
                        step > index + 1
                          ? "active"
                          : step === index + 1
                          ? "active"
                          : "default"
                      }`}
                      style={{
                        width: "50px",
                        height: "50px",
                        lineHeight: "50px",
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                      onClick={() => {
                        if (index + 1 <= step) {
                          setStep(index + 1);
                        }
                      }}
                    >
                      {index + 1}
                    </div>
                    <span
                      style={{
                        fontWeight: step === index + 1 ? "bold" : "normal",
                        fontSize: "10px",
                        lineHeight: "px",
                      }}
                      className="mt-1 "
                    >
                      {label.title}
                    </span>
                  </div>
                  {index < totalSteps - 1 && (
                    <ProgressBar
                      now={progress}
                      className="my-2 w-100"
                      style={{ height: "8px" }}
                    />
                  )}
                </div>
              );
            })}
          </div>

          {/* Render Form Fields */}
          <Form>
            {supplierRegistrationFormData.steps
              .filter((stepItem) => stepItem.step === step)
              .map((stepItem) => {
                return (
                  <div key={stepItem.step}>
                    <h1>{stepItem.title}</h1>
                    <Row>
                      {stepItem.fields.map((field) => (
                        <Col key={field.id} md={field.width} className="mb-3">
                          <Form.Group controlId={field.id}>
                            <Form.Label>
                              {field.label &&
                                field.type !== "checkbox" &&
                                field.label}
                              {field.required && (
                                <span style={{ color: "red" }}> *</span>
                              )}
                            </Form.Label>
                            {field.type === "checkbox-group" ? (
                              <div className="categories">
                                {field.groups.map((group, groupIndex) => (
                                  <div key={`${field.id}-group-${groupIndex}`}>
                                    <h6>{group.title}</h6>
                                    {group.options.map((option, index) => (
                                      <Form.Check
                                        key={`${field.id}-group-${groupIndex}-${index}`}
                                        id={`${field.id}-${groupIndex}-${index}`}
                                        type="checkbox"
                                        label={option.label}
                                        value={option.value}
                                        name={field.id}
                                        onChange={() =>
                                          handleCheckboxChange(
                                            field.id,
                                            option.value
                                          )
                                        }
                                        checked={
                                          selectedOptions[field.id]?.includes(
                                            option.value
                                          ) || false
                                        }
                                      />
                                    ))}
                                  </div>
                                ))}
                              </div>
                            ) : field.type === "select" ? (
                              <Form.Select required={field.required}>
                                {field.options.map((option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </option>
                                ))}
                              </Form.Select>
                            ) : field.type === "textarea" ? (
                              <Form.Control
                                as="textarea"
                                rows={field.row}
                                required={field.required}
                              />
                            ) : field.type === "checkbox" ? (
                              <Form.Check
                                id={`checkbox-${field.id}`}
                                type="checkbox"
                                label={field.label}
                                value={field.label}
                                onChange={() =>
                                  handleCheckboxChange(field.id, field.label)
                                }
                                checked={
                                  selectedOptions[field.id]?.includes(
                                    field.label
                                  ) || false
                                }
                              />
                            ) : (
                              <Form.Control
                                type={field.type}
                                required={field.required}
                                value={field.value && field.value}
                                disabled={field.disabled}
                              />
                            )}
                          </Form.Group>
                        </Col>
                      ))}
                    </Row>
                  </div>
                );
              })}

            {/* Navigation Buttons */}
            <div className="d-flex justify-content-between">
              <CustomButton
                buttonType={step === 1 ? "disabled" : "primaryOutline"}
                onClick={prevStep}
                disabled={step === 1}
              >
                Previous
              </CustomButton>

              <CustomButton buttonType="primary" onClick={nextStep}>
                {step === totalSteps ? "Submit" : "Next"}
              </CustomButton>
            </div>
          </Form>
        </div>
      </Container>
    </section>
  );
};

export default RegisterPage;
