import React, { useState } from "react";
import {
  Form,
  Button,
  ProgressBar,
  Row,
  Col,
  Container,
} from "react-bootstrap";
import "./SingupOnboarding.styles.scss";
import clientRegistrationFormData from "../../clientRegistrationFormData.json";
import supplierRegistrationFormData from "../../supplierRegistrationFormData.json";
import CustomButton from "../../components/CustomButton/CustomButton";
import { useParams } from "react-router-dom";
const SingupOnboarding = () => {
  const [step, setStep] = useState(1);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [formData, setFormData] = useState({});

  const { name } = useParams();
  const data =
    name === "supplier"
      ? supplierRegistrationFormData
      : name === "client"
      ? clientRegistrationFormData
      : null;
  console.log(clientRegistrationFormData);
  const totalSteps = data?.steps.length;
  const handleSignUp = async (e) => {
    e.preventDefault();
    console.log("Signup submitted", formData);
    // Implement your signup logic here
  };

  const handleInputChange = (event) => {
    const { name, value, type, files, checked } = event.target;

    setFormData((prevData) => {
      if (type === "checkbox") {
        const updatedCheckboxValues = prevData[name] || [];
        if (checked) {
          return {
            ...prevData,
            [name]: [...updatedCheckboxValues, value],
          };
        } else {
          return {
            ...prevData,
            [name]: updatedCheckboxValues.filter((item) => item !== value),
          };
        }
      } else if (type === "file") {
        return {
          ...prevData,
          [name]: files[0], // Single file upload (store first file in formData)
        };
      } else {
        return {
          ...prevData,
          [name]: value,
        };
      }
    });
  };
  console.log(formData);

  const nextStep = (e) => {
    e.preventDefault();
    setStep((prev) => Math.min(prev + 1, totalSteps));
  };

  const prevStep = (e) => {
    e.preventDefault();
    setStep((prev) => Math.max(prev - 1, 1));
  };

  const renderSummary = () => {
    const formatKey = (key) => {
      return key
        .replace(/([a-z0-9])([A-Z])/g, "$1 $2") // Adds space between lowercase and uppercase letters
        .replace(/^./, (str) => str.toUpperCase()); // Capitalizes the first letter of the string
    };

    return (
      <div>
        <ul className="row">
          {Object.keys(formData).map((key) => (
            <li key={key} className="col-md-6 inline-flex" md={6}>
              <strong>{formatKey(key)}:</strong> {JSON.stringify(formData[key])}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <section className="SingupOnboarding">
      <Container>
        <div className="p-4">
          {/* Step Progress */}
          <div className="d-flex justify-content-center align-items-center mb-3 gap-5">
            {data &&
              data.steps.map((label, index) => {
                const progress =
                  step > index + 1 ? 100 : step === index + 1 ? 50 : 0;

                return (
                  <div
                    key={index}
                    className="d-flex align-items-center w-100 justify-content-center text gap-0"
                  >
                    <div className="w-100">
                      <div
                        className={`rounded-circle ${
                          step > index + 1
                            ? "active"
                            : step === index + 1
                            ? "active"
                            : "default"
                        }`}
                        style={{
                          width: "50px",
                          height: "50px",
                          lineHeight: "50px",
                          textAlign: "center",
                          fontWeight: "bold",
                        }}
                        onClick={() => {
                          if (index + 1 <= step) {
                            setStep(index + 1);
                          }
                        }}
                      >
                        {index + 1}
                      </div>
                      <span
                        style={{
                          fontWeight: step === index + 1 ? "bold" : "normal",
                          fontSize: "10px",
                        }}
                        className="mt-1"
                      >
                        {label.title}
                      </span>
                    </div>
                    {index < totalSteps - 1 && (
                      <ProgressBar
                        now={progress}
                        className="my-2 w-100"
                        style={{ height: "8px" }}
                      />
                    )}
                  </div>
                );
              })}
          </div>

          {/* Render Form Fields */}
          <Form>
            {data &&
              data.steps
                .filter((stepItem) => stepItem.step === step)
                .map((stepItem) => (
                  <div key={stepItem.step}>
                    <h1>{stepItem.title}</h1>
                    <Row>
                      {stepItem?.fields?.map((field) => (
                        <Col key={field.id} md={field.width} className="mb-3">
                          <Form.Group controlId={field.id}>
                            <Form.Label>
                              {field.label &&
                                field.type !== "checkbox" &&
                                field.label}
                              {field.required && (
                                <span style={{ color: "red" }}> *</span>
                              )}
                            </Form.Label>
                            {field.type === "checkbox-group" ? (
                              <div className="categories">
                                {field.groups.map((group, groupIndex) => (
                                  <div key={`${field.id}-group-${groupIndex}`}>
                                    <h6>{group.title}</h6>
                                    {group.options.map((option, index) => (
                                      <Form.Check
                                        id={`${field.id}-group-${groupIndex}-${index}`}
                                        type="checkbox"
                                        label={option.label}
                                        value={option.value}
                                        name={field.name}
                                        onChange={(e) =>
                                          handleInputChange({
                                            target: {
                                              name: field.name,
                                              value: option.value,
                                              type: "checkbox",
                                              checked: e.target.checked,
                                            },
                                          })
                                        }
                                        checked={
                                          formData[field.name]?.includes(
                                            option.value
                                          ) || false
                                        }
                                      />
                                    ))}
                                  </div>
                                ))}
                              </div>
                            ) : field.type === "select" ? (
                              <Form.Select
                                name={field.id}
                                onChange={handleInputChange}
                                required={field.required}
                                defaultValue=""
                              >
                                <option value="" disabled>
                                  Choose one
                                </option>
                                {field.options.map((option, index) => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </option>
                                ))}
                              </Form.Select>
                            ) : field.type === "textarea" ? (
                              <Form.Control
                                as="textarea"
                                rows={field.row}
                                required={field.required}
                                onChange={handleInputChange}
                                name={field.name}
                                value={formData[field.name] || ""}
                              />
                            ) : field.type === "checkbox" ? (
                              <Form.Check
                                id={`checkbox-${field.id}`}
                                type="checkbox"
                                label={field.label}
                                value={field.label}
                                onChange={() =>
                                  handleInputChange({
                                    target: {
                                      name: field.name,
                                      value: field.label,
                                      type: "checkbox",
                                      checked: !formData[field.name]?.includes(
                                        field.label
                                      ),
                                    },
                                  })
                                }
                                checked={
                                  formData[field.name]?.includes(field.label) ||
                                  false
                                }
                              />
                            ) : field.type === "file" ? (
                              <Form.Control
                                type="file"
                                required={field.required}
                                onChange={handleInputChange}
                                name={field.name}
                              />
                            ) : (
                              <Form.Control
                                type={field.type}
                                required={field.required}
                                value={
                                  field.disabled
                                    ? field.value
                                    : formData[field.name] || ""
                                }
                                disabled={field.disabled}
                                onChange={handleInputChange}
                                name={field.name}
                              />
                            )}
                          </Form.Group>
                        </Col>
                      ))}
                    </Row>
                  </div>
                ))}

            {/* Summary Step */}
            {step === totalSteps && renderSummary()}

            {/* Navigation Buttons */}
            <div className="d-flex justify-content-between mt-5">
              <CustomButton
                buttonType={step === 1 ? "disabled" : "primaryOutline"}
                onClick={prevStep}
                disabled={step === 1}
              >
                Previous
              </CustomButton>

              <CustomButton buttonType="primary" onClick={nextStep}>
                {step === totalSteps ? "Submit" : "Next"}
              </CustomButton>
            </div>
          </Form>
        </div>
      </Container>
    </section>
  );
};

export default SingupOnboarding;
