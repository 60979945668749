import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Footer.styles.scss";
import { Link } from "react-router-dom";
import "./Footer.styles.scss";
const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <section className="Footer">
      <Container>
        <Row className="d-flex align-items-center row-gap-5">
          <Col
            lg={3}
            md={3}
            sm={12}
            className="d-flex justify-content-center justify-content-md-start"
          >
            <img src="/assets/Images/footer-logo.png" alt="Decor tec logo" />
          </Col>
          <Col lg={6} md={6} sm={12}>
            <ul className="d-flex justify-content-center align-items-center gap-4 mb-0 Footer__links">
              <Link className="nav-link" to="/">
                <li>Home</li>
              </Link>
              <Link className="nav-link" to="/about-us">
                <li>About Us</li>
              </Link>
              <Link className="nav-link" to="/faqs">
                <li>FAQ</li>
              </Link>
              <Link className="nav-link" to="/contact-us">
                <li>Contact Us</li>
              </Link>
            </ul>
          </Col>
          <Col
            lg={3}
            md={3}
            sm={12}
            className="d-flex justify-content-center justify-content-md-end align-items-center gap-4 Footer__socials"
          >
            <Link>
              <img src="/assets/svg/facebook.svg" alt="" />
            </Link>
            <Link>
              <img src="/assets/svg/instagram.svg" alt="" />
            </Link>
            <Link>
              <img src="/assets/svg/x.svg" alt="" />
            </Link>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col className="d-md-flex  justify-content-center align-items-center mt-4  Footer__copyright">
            <p className="mb-0 text-center text-md-left">
              {currentYear} Decor Tec. All right reserved.
            </p>
            <ul className="d-flex justify-content-center align-items-center gap-4 mb-0 ">
              <Link className="nav-link" to="/privacy-policy">
                <li>Privacy Policy</li>
              </Link>
              <Link className="nav-link" to="/terms-of-service">
                <li>Terms of Service</li>
              </Link>
              <Link className="nav-link" to="/cookie-policy">
                <li>Cookies Policy</li>
              </Link>
            </ul>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Footer;
