import React from "react";
import SignUp from "../../components/Signup/Signup";

const SignupPage = () => {
  return (
    <div>
      <SignUp />
    </div>
  );
};

export default SignupPage;
