import React from "react";
import { Card, Container } from "react-bootstrap";
import CustomButton from "../CustomButton/CustomButton";

const Subscription = () => {
  return (
    <Container className="mt-5">
      <Card>
        <Card.Header>
          <h4>Subscription</h4>
        </Card.Header>
        <Card.Body>
          <img
            src="/assets/svg/verification-icon.svg"
            alt="verification icon"
          />
          <Card.Title>You’re Subscribed!</Card.Title>
          <Card.Text>
            Thank you for subscribing to Decor Tec! Your subscription is active
            until [Expiry Date]. Enjoy full access to all features and stay
            connected with clients and suppliers.
          </Card.Text>
          <p>Next Renewal Date: [Renewal Date]</p>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Subscription;
