import React from "react";
import "./ContactForm.styles.scss";

import { Col, Container, Form, Row } from "react-bootstrap";
import CustomButton from "../../components/CustomButton/CustomButton";
const ContactForm = ({ fields }) => {
  return (
    <section>
      <Container>
        <Form>
          <Row>
            <h2 className="h2">Send Us a Message</h2>
          </Row>
          <Row>
            {fields.map((field) => (
              <Col key={field.id} md={field.width} className="mb-3">
                <Form.Group controlId={field.id}>
                  <Form.Label>
                    {field.label}
                    {field.required && <span style={{ color: "red" }}> *</span>}
                  </Form.Label>
                  {field.type === "select" ? (
                    <Form.Select required={field.required}>
                      {field.options.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>
                  ) : field.type === "textarea" ? (
                    <Form.Control
                      as="textarea"
                      rows={field.row}
                      required={field.required}
                    />
                  ) : (
                    <Form.Control type={field.type} required={field.required} />
                  )}
                </Form.Group>
              </Col>
            ))}
          </Row>
          <CustomButton>SEND MESSAGE</CustomButton>
        </Form>
      </Container>
    </section>
  );
};

export default ContactForm;
