import React from "react";
import Hero from "../../components/Hero/Hero";
import IconBoxList from "../../components/IconBoxList/IconBoxList";
import clientBenefits from "../../clientBenefits.json";
import supplierBenefits from "../../supplierBenefits.json";
import HowItWorks from "../../components/HowItWorks/HowItWorks";

import GlideSlider from "../../components/GlideSlider/GlideSlider";
import { Container } from "react-bootstrap";
import Categories from "../../components/Categories/Categories";
import CallToAction from "../../components/CallToAction/CallToAction";
const HomePage = () => {
  return (
    <div>
      <Hero />
      <IconBoxList data={clientBenefits} />
      <IconBoxList data={supplierBenefits} />
      <HowItWorks />
      <Categories />
      <CallToAction
        heading="Ready to Bring Your Event to Life or Grow Your Business?"
        paragraph="Whether you're planning your next event or looking to showcase your services to potential clients, Decor Tec has got you covered."
        buttons={[
          {
            buttonText: "Join as a Client",
            buttonType: "whiteOutline",
            buttonLink: "/signup",
          },
          {
            buttonText: "Signup as a Supplier",
            buttonType: "white",
            buttonLink: "/signup",
          },
        ]}
      />
    </div>
  );
};

export default HomePage;
