import React from "react";
import "./DashboardSidebar.styles.scss";
import { Col, Row } from "react-bootstrap";
import CustomButton from "../CustomButton/CustomButton";
const DashboardSidebar = () => {
  return (
    <div className="DashboardSidebar">
      <Row className="gap-3">
        <Col md={12} className="DashboardSidebar__image">
          <img
            src="/assets/images/profile.png"
            alt="profile"
            className="img-fluid"
          />
        </Col>
        <Col md={12} className="DashboardSidebar__title">
          <div className="DashboardSidebar__title-item">
            <h4 className="h4">Elegant Events & Decor</h4>
            <img
              src="/assets/svg/verified-check.svg"
              alt="verification check "
            />
          </div>
          <p>25481/2024</p>
        </Col>
        <Col md={12} className="DashboardSidebar__profileSummary">
          <ul>
            <li className="DashboardSidebar__profileSummary-item">
              <span className="DashboardSidebar__profileSummary-image">
                <img src="/assets/svg/email.svg" alt="email envelope" />
              </span>
              <div className="DashboardSidebar__profileSummary-main">
                <p>johnsmith@elegantdecor.co.za </p>
              </div>
            </li>
            <li className="DashboardSidebar__profileSummary-item">
              <span className="DashboardSidebar__profileSummary-image">
                <img src="/assets/svg/phone.svg" alt="phone icon" />
              </span>
              <div className="DashboardSidebar__profileSummary-main">
                <p>+27 11 234 5678 </p>
              </div>
            </li>
            <li className="DashboardSidebar__profileSummary-item">
              <span className="DashboardSidebar__profileSummary-image">
                <img src="/assets/svg/address.svg" alt="map marker icon" />
              </span>
              <p className="DashboardSidebar__profileSummary-main">
                123 Main Street
                <br />
                Sandton
                <br />
                Johannesburg
                <br />
                Gauteng
                <br />
                South Africa
                <br />
                2196
              </p>
            </li>
          </ul>
        </Col>
        <Row md={12} className="DashboardSidebar__logout">
          <CustomButton buttonType="primaryOutline">Log Out</CustomButton>
        </Row>
      </Row>
    </div>
  );
};

export default DashboardSidebar;
