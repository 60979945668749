import React from "react";
import "./HowItWorks.styles.scss";
import { Col, Container, Row } from "react-bootstrap";
const HowItWorks = () => {
  return (
    <section className="HowItWorks">
      <Container>
        <Row className="row-gap-5">
          <Col lg={4} md={6}>
            <h2 className="h2">
              How <span>Decor Tec</span> Works
            </h2>
          </Col>
          <Col lg={4} md={6}>
            <div className="HowItWorks__item">
              <div className="HowItWorks__item-number">
                <svg
                  width="41"
                  height="40"
                  viewBox="0 0 41 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.666656"
                    width="40"
                    height="40"
                    rx="20"
                    fill="url(#paint0_linear_18_335)"
                  />
                  <path
                    d="M16.3147 14.296V10.48H22.9627V28H18.6907V14.296H16.3147Z"
                    fill="white"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_18_335"
                      x1="20.544"
                      y1="-8.07692"
                      x2="20.9713"
                      y2="39.9973"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#8B268C" />
                      <stop offset="1" stop-color="#465EA6" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div className="HowItWorks__item-content">
                <h5 className="h5">Sign Up</h5>
                <p>
                  Clients and suppliers create profiles tailored to their needs.
                </p>
              </div>
            </div>
          </Col>
          <Col lg={4} md={6}>
            <div className="HowItWorks__item">
              <div className="HowItWorks__item-number">
                <svg
                  width="41"
                  height="40"
                  viewBox="0 0 41 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.333374"
                    width="40"
                    height="40"
                    rx="20"
                    fill="url(#paint0_linear_18_337)"
                  />
                  <path
                    d="M14.2694 24.712C14.8134 24.28 15.0614 24.08 15.0134 24.112C16.5814 22.816 17.8134 21.752 18.7094 20.92C19.6214 20.088 20.3894 19.216 21.0134 18.304C21.6374 17.392 21.9494 16.504 21.9494 15.64C21.9494 14.984 21.7974 14.472 21.4934 14.104C21.1894 13.736 20.7334 13.552 20.1254 13.552C19.5174 13.552 19.0374 13.784 18.6854 14.248C18.3494 14.696 18.1814 15.336 18.1814 16.168H14.2214C14.2534 14.808 14.5414 13.672 15.0854 12.76C15.6454 11.848 16.3734 11.176 17.2694 10.744C18.1814 10.312 19.1894 10.096 20.2934 10.096C22.1974 10.096 23.6294 10.584 24.5894 11.56C25.5654 12.536 26.0534 13.808 26.0534 15.376C26.0534 17.088 25.4694 18.68 24.3014 20.152C23.1334 21.608 21.6454 23.032 19.8374 24.424H26.3174V27.76H14.2694V24.712Z"
                    fill="white"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_18_337"
                      x1="20.2107"
                      y1="-8.07692"
                      x2="20.638"
                      y2="39.9973"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#8B268C" />
                      <stop offset="1" stop-color="#465EA6" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div className="HowItWorks__item-content">
                <h5>Profile Review & Approval</h5>
                <p>DecorTec reviews profiles to ensure quality and trust.</p>
              </div>
            </div>
          </Col>
          <Col lg={4} md={6}>
            <div className="HowItWorks__item">
              <div className="HowItWorks__item-number">
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="40"
                    height="40"
                    rx="20"
                    fill="url(#paint0_linear_18_340)"
                  />
                  <path
                    d="M13.724 15.424C13.788 13.712 14.348 12.392 15.404 11.464C16.46 10.536 17.892 10.072 19.7 10.072C20.9 10.072 21.924 10.28 22.772 10.696C23.636 11.112 24.284 11.68 24.716 12.4C25.164 13.12 25.388 13.928 25.388 14.824C25.388 15.88 25.124 16.744 24.596 17.416C24.068 18.072 23.452 18.52 22.748 18.76V18.856C23.66 19.16 24.38 19.664 24.908 20.368C25.436 21.072 25.7 21.976 25.7 23.08C25.7 24.072 25.468 24.952 25.004 25.72C24.556 26.472 23.892 27.064 23.012 27.496C22.148 27.928 21.116 28.144 19.916 28.144C17.996 28.144 16.46 27.672 15.308 26.728C14.172 25.784 13.572 24.36 13.508 22.456H17.492C17.508 23.16 17.708 23.72 18.092 24.136C18.476 24.536 19.036 24.736 19.772 24.736C20.396 24.736 20.876 24.56 21.212 24.208C21.564 23.84 21.74 23.36 21.74 22.768C21.74 22 21.492 21.448 20.996 21.112C20.516 20.76 19.74 20.584 18.668 20.584H17.9V17.248H18.668C19.484 17.248 20.14 17.112 20.636 16.84C21.148 16.552 21.404 16.048 21.404 15.328C21.404 14.752 21.244 14.304 20.924 13.984C20.604 13.664 20.164 13.504 19.604 13.504C18.996 13.504 18.54 13.688 18.236 14.056C17.948 14.424 17.78 14.88 17.732 15.424H13.724Z"
                    fill="white"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_18_340"
                      x1="19.8773"
                      y1="-8.07692"
                      x2="20.3046"
                      y2="39.9973"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#8B268C" />
                      <stop offset="1" stop-color="#465EA6" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div className="HowItWorks__item-content">
                <h5>Matching Process</h5>
                <p>
                  Decor Tec manually matches clients with suitable suppliers.
                </p>
              </div>
            </div>
          </Col>
          <Col lg={4} md={6}>
            <div className="HowItWorks__item">
              <div className="HowItWorks__item-number">
                <svg
                  width="41"
                  height="40"
                  viewBox="0 0 41 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.666656"
                    width="40"
                    height="40"
                    rx="20"
                    fill="url(#paint0_linear_18_343)"
                  />
                  <path
                    d="M13.3427 24.88V21.568L20.9267 10.72H25.5107V21.328H27.4787V24.88H25.5107V28H21.4067V24.88H13.3427ZM21.6947 15.232L17.6627 21.328H21.6947V15.232Z"
                    fill="white"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_18_343"
                      x1="20.544"
                      y1="-8.07692"
                      x2="20.9713"
                      y2="39.9973"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#8B268C" />
                      <stop offset="1" stop-color="#465EA6" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div className="HowItWorks__item-content">
                <h5>Booking & Payment</h5>
                <p>
                  Clients select a supplier, make a payment, and secure the
                  booking.
                </p>
              </div>
            </div>
          </Col>
          <Col lg={4} md={6}>
            <div className="HowItWorks__item">
              <div className="HowItWorks__item-number">
                <svg
                  width="41"
                  height="40"
                  viewBox="0 0 41 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.333374"
                    width="40"
                    height="40"
                    rx="20"
                    fill="url(#paint0_linear_18_346)"
                  />
                  <path
                    d="M25.7494 14.056H18.2614V17.368C18.5814 17.016 19.0294 16.728 19.6054 16.504C20.1814 16.28 20.8054 16.168 21.4774 16.168C22.6774 16.168 23.6694 16.44 24.4534 16.984C25.2534 17.528 25.8374 18.232 26.2054 19.096C26.5734 19.96 26.7574 20.896 26.7574 21.904C26.7574 23.776 26.2294 25.264 25.1734 26.368C24.1174 27.456 22.6294 28 20.7094 28C19.4294 28 18.3174 27.784 17.3734 27.352C16.4294 26.904 15.7014 26.288 15.1894 25.504C14.6774 24.72 14.3974 23.816 14.3494 22.792H18.3574C18.4534 23.288 18.6854 23.704 19.0534 24.04C19.4214 24.36 19.9254 24.52 20.5654 24.52C21.3174 24.52 21.8774 24.28 22.2454 23.8C22.6134 23.32 22.7974 22.68 22.7974 21.88C22.7974 21.096 22.6054 20.496 22.2214 20.08C21.8374 19.664 21.2774 19.456 20.5414 19.456C19.9974 19.456 19.5494 19.592 19.1974 19.864C18.8454 20.12 18.6134 20.464 18.5014 20.896H14.5414V10.456H25.7494V14.056Z"
                    fill="white"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_18_346"
                      x1="20.2107"
                      y1="-8.07692"
                      x2="20.638"
                      y2="39.9973"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#8B268C" />
                      <stop offset="1" stop-color="#465EA6" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div className="HowItWorks__item-content">
                <h5>Event Completion & Feedback</h5>
                <p>
                  After the event, clients can rate and review the supplier.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HowItWorks;
