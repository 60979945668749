import React from "react";
import "./CustomButton.styles.scss";
const CustomButton = ({
  buttonType = "primary",
  children,
  google = false,
  onClick,
}) => {
  return (
    <button
      // className={`custom-button ${
      //   (primary && "primary") ||
      //   (primaryOutline && "primaryOutline") ||
      //   (white && "white") ||
      //   (whiteOutline && "whiteOutline")
      // } `}
      className={`custom-button ${buttonType}`}
      onClick={onClick}
    >
      {google && (
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.9996 8.79002C17.9996 12.9399 15.7896 17.9998 9.12977 17.9998C4.12451 18.033 0.038519 14.0052 0 9.00002C0.038519 3.99475 4.12451 -0.0330547 9.12977 0.000204501C11.2004 0.00788431 13.2082 0.712357 14.8296 2.00015C14.9416 2.09168 15.0105 2.22576 15.0196 2.37015C15.0202 2.51623 14.9626 2.65655 14.8596 2.76014C14.2086 3.35532 13.5879 3.98276 12.9997 4.64009C12.8286 4.82838 12.5419 4.85444 12.3397 4.70009C11.4158 4.01638 10.2885 3.66409 9.13977 3.70011C6.18513 3.70011 3.78991 6.09533 3.78991 9.05002C3.78991 12.0046 6.18513 14.3999 9.13977 14.3999C12.1397 14.3999 13.4097 13.1199 14.0696 10.85H9.49976C9.22367 10.85 8.99978 10.6261 8.99978 10.35V7.70005C8.99978 7.42386 9.22367 7.20007 9.49976 7.20007H17.4996C17.7298 7.19857 17.924 7.37116 17.9496 7.60006C17.9867 7.99555 18.0034 8.39273 17.9996 8.79002Z"
            fill="url(#paint0_linear_121_289)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_121_289"
              x1="8.94483"
              y1="-3.63462"
              x2="9.13712"
              y2="17.9988"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#8B268C" />
              <stop offset="1" stop-color="#465EA6" />
            </linearGradient>
          </defs>
        </svg>
      )}
      {children}
    </button>
  );
};

export default CustomButton;
