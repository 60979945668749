import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./Navigation.styles.scss";
import { Link, NavLink } from "react-router-dom";
import CustomButton from "../CustomButton/CustomButton";

function Navigation() {
  return (
    <Navbar expand="lg" className=" Navigation">
      <Container>
        <Navbar.Brand href="#" className="flex-1">
          <img src="/assets/images/Navigation-logo.png" alt="Decor Tec Logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <span></span>
          <span></span>
          <span></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto my-2 my-lg-0" style={{ maxHeight: "100px" }}>
            <NavLink to="/" className="nav-link">
              Home
            </NavLink>
            <NavLink to="/about-us" className="nav-link">
              About Us
            </NavLink>
            <NavLink to="/faqs" className="nav-link">
              FAQ
            </NavLink>
            <NavLink to="/contact-us" className="nav-link">
              Contact Us
            </NavLink>
            <div className="d-lg-none">
              <NavLink className="nav-link" to="login">
                Login
              </NavLink>

              <NavLink className="nav-link" to="/signup">
                Signup
              </NavLink>
            </div>
          </Nav>
          <div className="d-none d-lg-flex Navigation__right">
            <NavLink className="nav-link" to="login">
              Login
            </NavLink>
            <Link to="signup">
              <CustomButton buttonType="white">Signup</CustomButton>
            </Link>
          </div>
        </Navbar.Collapse>
      </Container>
      <div className="Navigation__background">
        <svg
          width="998"
          viewBox="0 -20 998 120"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M48.4828 0H998V72H48.4828H0L48.4828 0Z"
            fill="url(#paint0_linear_78_1352)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_78_1352"
              x1="372.316"
              y1="-49.5001"
              x2="264.128"
              y2="141.13"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#8B268C" />
              <stop offset="1" stop-color="#465EA6" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </Navbar>
  );
}

export default Navigation;
