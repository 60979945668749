import { Card, Container, Form } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import CustomButton from "../CustomButton/CustomButton";
import { Link } from "react-router-dom";
import "./Signup.styles.scss";

function SignUp() {
  const clientList = [
    "Access a curated list of top suppliers.",
    "Customize your event preferences.",
    "Enjoy a seamless booking and planning experience.",
  ];
  const supplierList = [
    "Showcase your portfolio and services.",
    "Receive direct client inquiries.",
    "Enjoy a seamless booking and planning Manage bookings and grow your reputation..",
  ];
  return (
    <section className="SignUp">
      <Container>
        <Row>
          <h2>
            Choose Your Path with <span>DecorTec</span>
          </h2>
          <p>
            Welcome to Decor Tec! Whether you're planning your next
            unforgettable event or offering top-notch decor and event services,
            we’ve got you covered. Choose the option that suits you best:
          </p>
        </Row>
        <Row className="mt-5 row-gap-3">
          <Col md={6}>
            <Card>
              <Card.Body>
                <img
                  src="/assets/svg/client.svg"
                  alt="verification icon"
                  className="img-fluid"
                />
                <Card.Title>For Clients</Card.Title>
                <Card.Text>
                  Looking for trusted suppliers to bring your event vision to
                  life? Register as a Client to
                </Card.Text>
                <ul>
                  {clientList.map((clientItem) => {
                    return (
                      <li>
                        <img src="/assets/svg/tick.svg" alt="" />
                        {clientItem}
                      </li>
                    );
                  })}
                </ul>

                <Link to="/signup/client" className="w-100 text-center">
                  <Row>
                    <CustomButton buttonType="primary" variant="primary">
                      Register Now
                    </CustomButton>
                  </Row>
                </Link>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card>
              <Card.Body>
                <img
                  src="/assets/svg/supplier.svg"
                  alt="verification icon"
                  className="img-fluid"
                />
                <Card.Title>For Supplier</Card.Title>
                <Card.Text>
                  Ready to grow your business and connect with clients looking
                  for your expertise? Register as a Supplier to:
                </Card.Text>
                <ul>
                  {clientList.map((clientItem) => {
                    return (
                      <li>
                        <img src="/assets/svg/tick.svg" alt="" />
                        {clientItem}
                      </li>
                    );
                  })}
                </ul>

                <Link to="/signup/supplier" className="w-100 text-center">
                  <Row>
                    <CustomButton buttonType="primary" variant="primary">
                      Register Now
                    </CustomButton>
                  </Row>
                </Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default SignUp;
