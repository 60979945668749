import React, { useEffect, useRef } from "react";
import Glide from "@glidejs/glide";
import "@glidejs/glide/dist/css/glide.core.min.css"; // Optional for default styles
import "@glidejs/glide/dist/css/glide.theme.min.css";
import "./GlideSlider.styles.scss";
const GlideSlider = ({ data }) => {
  const glideRef = useRef(null);

  useEffect(() => {
    const glide = new Glide(glideRef.current, {
      type: "carousel",
      startAt: 0,
      perView: 5,
      gap: 6,
      breakpoints: {
        1200: {
          perView: 3, // Show 3 slides on screens ≤ 1200px
        },
        768: {
          perView: 2, // Show 2 slides on screens ≤ 768px
        },
        480: {
          perView: 2, // Show 1 slide on screens ≤ 480px
        },
      },
    });

    glide.mount();

    return () => glide.destroy(); // Clean up on unmount
  }, []);

  return (
    <div ref={glideRef} className="glide mt-5">
      <div className="glide__track" data-glide-el="track">
        <ul className="glide__slides">
          {data.categories.map((cat) => {
            return (
              <li className="glide__slide">
                <img src={cat.image} alt="" className="img-fluid" />
                <p>{cat.name}</p>
              </li>
            );
          })}
        </ul>
      </div>

      <div className="glide__bullets" data-glide-el="controls[nav]"></div>

      {/* Optional Navigation */}
      <div className="glide__arrows" data-glide-el="controls">
        <button className="glide__arrow glide__arrow--left" data-glide-dir="<">
          <svg
            width="56"
            height="57"
            viewBox="0 0 56 57"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.5"
              y="1"
              width="55"
              height="55"
              rx="27.5"
              fill="#465EA6"
            />
            <rect
              x="0.5"
              y="1"
              width="55"
              height="55"
              rx="27.5"
              stroke="#465EA6"
            />
            <path
              d="M36 27.5H23.83L29.42 21.91L28 20.5L20 28.5L28 36.5L29.41 35.09L23.83 29.5H36V27.5Z"
              fill="white"
            />
          </svg>
        </button>
        <button className="glide__arrow glide__arrow--right" data-glide-dir=">">
          <svg
            width="56"
            height="57"
            viewBox="0 0 56 57"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.5"
              y="1"
              width="55"
              height="55"
              rx="27.5"
              fill="#465EA6"
            />
            <rect
              x="0.5"
              y="1"
              width="55"
              height="55"
              rx="27.5"
              stroke="#465EA6"
            />
            <path
              d="M28 20.5L26.59 21.91L32.17 27.5H20V29.5H32.17L26.59 35.09L28 36.5L36 28.5L28 20.5Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default GlideSlider;
