import React from "react";
import { Card, Container } from "react-bootstrap";
import CustomButton from "../CustomButton/CustomButton";

const Verification = () => {
  return (
    <Container className="mt-5">
      <Card>
        <Card.Header>
          <h4>Verification</h4>
        </Card.Header>
        <Card.Body>
          <img
            src="/assets/svg/verification-icon.svg"
            alt="verification icon"
          />
          <Card.Title>Your Profile is Verified!</Card.Title>
          <Card.Text>
            You’ve successfully completed the verification process. Clients can
            now trust your profile, giving you greater visibility and
            credibility on Decor Tec. Keep your profile updated to attract more
            opportunities!
          </Card.Text>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Verification;
