import React from "react";
import Faqs from "../../components/Faqs/Faqs";
import CallToAction from "../../components/CallToAction/CallToAction";

const FaqsPage = () => {
  return (
    <div>
      <Faqs />
      <CallToAction
        heading="Still have a question?"
        buttons={[
          {
            buttonText: "Contact Us Now",
            buttonType: "whiteOutline",
            buttonLink: "/contact-us",
          },
        ]}
        buttonsPosition="end"
      />
    </div>
  );
};

export default FaqsPage;
