import React from "react";
import HomePage from "../pages/HomePage/HomePage";
import { Route, Routes } from "react-router-dom";
import AboutPage from "../pages/AboutPage/AboutPage";
import ContactPage from "../pages/ContactPage/ContactPage";
import FaqsPage from "../pages/FaqsPage/FaqsPage";
import LoginPage from "../pages/LoginPage/LoginPage";
import SignupPage from "../pages/SignupPage/SignupPage";
import CookiePolicy from "../pages/CookiePolicy/CookiePolicy";
import TermsOfService from "../pages/TermsOfService/TermsOfService";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import RegisterPage from "../pages/RegisterPage/RegisterPage";
import DashboardPage from "../pages/DashboardPage/DashboardPage";
import ProfileManagement from "../components/ProfileManagement/ProfileManagement";
import Portfolio from "../components/Portfolio/Portfolio";
import Verification from "../components/Verification/Verification";
import Subscription from "../components/Subscription/Subscription";
import SingupOnboarding from "../components/SingupOnboarding/SingupOnboarding";

const NavigationRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/about-us" element={<AboutPage />} />
      <Route path="/contact-us" element={<ContactPage />} />
      <Route path="/faqs" element={<FaqsPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/signup" element={<SignupPage />} />
      <Route path="/signup/:name" element={<SingupOnboarding />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/dashboard" element={<DashboardPage />}>
        <Route index element={<ProfileManagement />} />
        <Route path="profile" element={<ProfileManagement />} />
        <Route path="portfolio" element={<Portfolio />} />
        <Route path="verification" element={<Verification />} />
        <Route path="subscription" element={<Subscription />} />
      </Route>
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-of-service" element={<TermsOfService />} />
      <Route path="/cookie-policy" element={<CookiePolicy />} />
    </Routes>
  );
};

export default NavigationRoutes;
