import React, { useState } from "react";
import { Form, Button, Container, Row, Col, Card } from "react-bootstrap";
import CustomButton from "../CustomButton/CustomButton";
import formSchema from "../../supplierRegistrationFormData.json";
const ProfileManagement = () => {
  // Initial dummy data
  const [formData, setFormData] = useState({
    businessType: "Registered Deco/Events Company",
    registrationNumber: "2023/123456/07",
    businessName: "Deco Master Co.",
    streetAddress: "123 Market Street",
    suburb: "Central",
    city: "Johannesburg",
    country: "South Africa",
    proofOfAddress: "",
    firstName: "John",
    lastName: "Doe",
    email: "john.doe@example.com",
    phoneNumber: "0123456789",
    facebook: "https://facebook.com/johndoe",
    instagram: "https://instagram.com/johndoe",
    twitter: "https://twitter.com/johndoe",
    linkedln: "https://linkedin.com/in/johndoe",
    youtube: "https://youtube.com/johndoe",
    website: "https://johndoe.com",
    marketing: true,
    preferences: {
      corporateEvents: ["Conferences"],
      socialEvents: ["Birthdays"],
      weddings: ["Receptions"],
    },
  });

  // Field schema
  const formSchema = {
    fields: [
      {
        id: "businessType",
        label: "Business Type",
        type: "select",
        width: "12",
        required: true,
        options: [
          {
            value: "Registered Deco/Events Company",
            label: "Registered Deco/Events Company",
          },
          {
            value: "Unregistered Deco/Events Company",
            label: "Unregistered Deco/Events Company",
          },
          { value: "Catering Company", label: "Catering Company" },
          { value: "Photography Services", label: "Photography Services" },
          { value: "Deco Product Supplier", label: "Deco Product Supplier" },
        ],
      },
      {
        id: "registrationNumber",
        label: "Company Registration Number",
        type: "text",
        width: "6",
      },
      {
        id: "businessName",
        label: "Registered Business Name",
        type: "text",
        width: "6",
      },
      {
        id: "preferences",
        label: "Choose Your Preferences",
        type: "checkbox-group",
        width: "12",
        groups: [
          {
            title: "Corporate Events",
            id: "corporateEvents",
            options: [
              { value: "Conferences", label: "Conferences" },
              { value: "Team-Building Events", label: "Team-Building Events" },
              { value: "Product Launches", label: "Product Launches" },
            ],
          },
          {
            title: "Social Events",
            id: "socialEvents",
            options: [
              { value: "Birthdays", label: "Birthdays" },
              { value: "Anniversaries", label: "Anniversaries" },
              { value: "Baby Showers", label: "Baby Showers" },
            ],
          },
          {
            title: "Weddings",
            id: "weddings",
            options: [
              {
                value: "Traditional Ceremonies",
                label: "Traditional Ceremonies",
              },
              { value: "Engagement Parties", label: "Engagement Parties" },
              { value: "Receptions", label: "Receptions" },
            ],
          },
        ],
      },
      {
        id: "streetAddress",
        label: "Street Address",
        type: "text",
        width: "12",
      },
      { id: "suburb", label: "Suburb", type: "text", width: "6" },
      { id: "city", label: "City/Town", type: "text", width: "6" },
      {
        id: "country",
        label: "Country",
        type: "text",
        width: "12",
        disabled: true,
      },
    ],
  };

  // Handle changes dynamically
  const handleChange = (fieldId, value) => {
    setFormData({ ...formData, [fieldId]: value });
  };

  const handleGroupCheckboxChange = (groupId, optionValue, isChecked) => {
    const updatedPreferences = { ...formData.preferences };
    if (isChecked) {
      updatedPreferences[groupId] = [
        ...(updatedPreferences[groupId] || []),
        optionValue,
      ];
    } else {
      updatedPreferences[groupId] = updatedPreferences[groupId].filter(
        (val) => val !== optionValue
      );
    }
    setFormData({ ...formData, preferences: updatedPreferences });
  };

  return (
    <Container className="mt-5">
      <Card>
        <Card.Header>
          <h4>Profile Information</h4>
        </Card.Header>
        <Card.Body>
          <Form>
            <Row>
              {formSchema.fields.map((field) => (
                <Col md={field.width} key={field.id} className="mb-3">
                  <Form.Group
                    className="d-flex flex-wrap gap-2"
                    controlId={field.id}
                  >
                    <Form.Label className="w-100 mt-3">
                      {field.label}
                    </Form.Label>
                    {field.type === "text" ||
                    field.type === "email" ||
                    field.type === "tel" ? (
                      <Form.Control
                        type={field.type}
                        value={formData[field.id] || ""}
                        onChange={(e) => handleChange(field.id, e.target.value)}
                        disabled={field.disabled || false}
                      />
                    ) : field.type === "select" ? (
                      <Form.Control
                        as="select"
                        value={formData[field.id] || ""}
                        onChange={(e) => handleChange(field.id, e.target.value)}
                      >
                        {field.options.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Form.Control>
                    ) : field.type === "checkbox-group" ? (
                      field.groups.map((group) => (
                        <div key={group.id} className="mb-3">
                          <h5>{group.title}</h5>
                          {group.options.map((option) => (
                            <Form.Check
                              key={option.value}
                              type="checkbox"
                              label={option.label}
                              checked={
                                formData.preferences[group.id]?.includes(
                                  option.value
                                ) || false
                              }
                              onChange={(e) =>
                                handleGroupCheckboxChange(
                                  group.id,
                                  option.value,
                                  e.target.checked
                                )
                              }
                            />
                          ))}
                        </div>
                      ))
                    ) : null}
                  </Form.Group>
                </Col>
              ))}
            </Row>
            <CustomButton
              buttonType="primary"
              onClick={() => alert(JSON.stringify(formData, null, 2))}
            >
              Save Changes
            </CustomButton>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ProfileManagement;
