import "./App.scss";
import Footer from "./components/Footer/Footer";

import Navigation from "./components/Navbar/Navigation";
import NavigationRoutes from "./NavigationRoutes/NavigationRoutes";
function App() {
  return (
    <div className="App">
      <Navigation />
      <NavigationRoutes />
      <Footer />
    </div>
  );
}

export default App;
