import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import CustomButton from "../CustomButton/CustomButton";
import fields from "../../loginfields.json";
import "./LogIn.styles.scss";
import { Link } from "react-router-dom";
function Login() {
  return (
    <section className="Login">
      <Container className="d-flex align-items-center justify-content-center">
        <Form>
          <Row>
            <h2 className="h2">Login</h2>
            <p>Access your dashboard</p>
          </Row>
          <Row>
            {fields.map((field) => (
              <Col key={field.id} md={field.width} className="mb-3">
                <Form.Group controlId={field.id}>
                  <Form.Label>
                    {field.label}
                    {field.required && <span style={{ color: "red" }}> *</span>}
                  </Form.Label>
                  {field.type === "select" ? (
                    <Form.Select required={field.required}>
                      {field.options.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>
                  ) : field.type === "textarea" ? (
                    <Form.Control
                      as="textarea"
                      rows={field.row}
                      required={field.required}
                    />
                  ) : (
                    <Form.Control type={field.type} required={field.required} />
                  )}
                </Form.Group>
              </Col>
            ))}
          </Row>
          <Row className="gap-3">
            <p>Forgot Your passoword?</p>
            <div className="buttons d-flex flex-column gap-3">
              <CustomButton>Log In</CustomButton>
              <CustomButton google buttonType="primaryOutline">
                Log in with Google
              </CustomButton>
            </div>
            <p>
              Don't have an account? <Link to="/signup">Sign Up</Link>
            </p>
          </Row>
        </Form>
      </Container>
    </section>
  );
}

export default Login;
