import React from "react";
import "./Dashboard.styles.scss";
import { NavLink, Outlet, useLocation } from "react-router-dom";
const Dashboard = () => {
  const location = useLocation();
  console.log(location.pathname === "/dashboard");
  return (
    <div className="Dashboard">
      <div className="Dashboard__navigation">
        <NavLink
          to="profile"
          className={`Dashboard__navigation-item ${
            location.pathname === "/dashboard" && "active"
          }`}
        >
          Profile Management
        </NavLink>
        <NavLink to="portfolio" className="Dashboard__navigation-item ">
          Portfolio
        </NavLink>
        <NavLink to="verification" className="Dashboard__navigation-item ">
          Verification
        </NavLink>
        <NavLink to="subscription" className="Dashboard__navigation-item ">
          Subscription
        </NavLink>
      </div>
      <Outlet />
    </div>
  );
};

export default Dashboard;
